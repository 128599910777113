<template>
    <v-card>
        <v-toolbar dark color="primary">
            <v-toolbar-title>Customer Billl</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="formClose">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-row>
            <v-col cols="12" md="6">
                <v-simple-table>
                    <template v-slot:default>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td class="font-weight-bold">Customer ID</td>
                            <td>{{getApiData.details.homestayCustomerID}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Countery / State</td>
                            <td>{{getApiData.details.country}} / {{getApiData.details.state}}</td>
                        </tr> 
                        <tr>
                            <td class="font-weight-bold">Check In Date</td>
                            <td>{{getApiData.details.checkInDate}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Check Out Date</td>
                            <td>{{getApiData.details.checkOutDate}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Number Of Person</td>
                            <td>{{getApiData.details.numberOfPerson}}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col cols="12" md="6">
                <v-simple-table>
                    <template v-slot:default>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td class="font-weight-bold">Room</td>
                            <td>{{getApiData.details.room}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Contact Number</td>
                            <td>{{getApiData.details.contactNumber}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Amount To Pay</td>
                            <td>{{getApiData.details.amountToPay}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Due Amount</td>
                            <td>{{getApiData.details.dueAmount}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Paid Amount</td>
                            <td>{{getApiData.details.paidAmount}}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>

        <hr>
        <v-form>
            <v-row>
                <v-col cols="12" md="3">
                    <v-text-field v-model="getApiData.details.dueAmount" readonly label="Due Amount"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field v-model="getApiData.details.paidAmount" type="number"
                                  label="Paid Amount"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-autocomplete
                            v-model="getApiData.details.paymentType"
                            :items="paymentType"
                            label="Payment Type"
                            clearable
                            dense
                            outlined
                            single-line
                            item-text="value"
                            item-value="id"
                    ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="3">
                    <v-switch
                            class="col-6"
                            v-model="getApiData.details.isCustomerCheckOut"
                            label="Checkout"
                            clearable
                    ></v-switch>
                </v-col>
                <v-col cols="12" md="3"></v-col>
            </v-row>
        </v-form>
        <div class="col-4">
            <v-btn
                    dark
                    depressed
                    class="mr-4 green darken-1"
                    @click="update"
            >update
            </v-btn>
        </div>
        <v-snackbar v-model="snackbar.snackbar" :timeout="snackbar.timeout" top color="error">
            {{ snackbar.text }}
            <v-btn text @click="snackbar.snackbar = false">Close</v-btn>
        </v-snackbar>
    </v-card>
</template>

<script>
    /*import { email, maxLength, required, sameAs } from "vuelidate/lib/validators";*/
    import axios from "axios";

    export default {
        name: "editAdminForm",
        props: ["homestayCustomerID", "pendingData"],
        watch: {
            dialog: {
                handler: function (val) {
                    console.log("watch", this.dialog, val);
                    if (this.dialog) {
                        this.$emit("formResponse", val);
                    }
                    this.dialog = true;
                },
                deep: true
            }
        },
        data() {
            return {
                e6: 1,
                step: 1,
                dialog: false,
                getApiData: [],
                snackbar: {
                    snackbar: false,
                    text: "Internal Server Error,Please Contact Admin",
                    timeout: 200000,
                    menu2: false,
                    save_button: false,
                    next_button: true,
                    success: false,
                    error: false,
                    number_of_second_form: 1,
                    homestayCustomerID: 1,
                    price: 0
                },
                paymentType: ['CASH', 'CHEQUE', 'CARD'],
                formDatas: {},
                BillForm: {},
                rooms: [],
                RoomObject: {},
                countries: "",
                state: ""
            };
        },
        computed: {},
        mounted() {
            this.formData();
        },
        methods: {
            formClose(val) {
                console.log(val)
                this.$emit("formResponseClose", val);
            },

            async formData() {
                console.log(this.homestayCustomerID);
                const response = await axios.get(
                    "Hotel/GetHomestayCustomerCheckOutDetails/" + this.homestayCustomerID
                );
                this.getApiData = response.data;
            },

            update() {
                let form = {
                    homestayCustomerID: this.getApiData.details.homestayCustomerID,
                    checkOutDate: parseFloat(this.getApiData.details.checkOutDate),
                    Amount: parseFloat(this.getApiData.details.amountToPay),
                    CustomerOut: this.getApiData.details.isCustomerCheckOut
                };

                axios
                    .post("Hotel/CheckOut", form)
                    .then(response => {
                        console.log(response.data);
                        this.snackbar.success = response.data.success;
                        this.snackbar.next_button = false;
                        this.snackbar.save_button = true;
                        this.dialog = true;
                    })
                    .catch(() => {
                        this.snackbar.error = true;
                        this.snackbar.snackbar = true;
                        this.snackbar.success = false;
                        this.snackbar.text = "Internal Server Error, Please Contact Admin";
                    });
            },
            reset() {
                this.save_button = false;
                this.e6 = 1;
                this.$emit("FormClose", this.dialogue);
                this.snackbar.next_button = true;
            }
        }
    };
</script>

<style scoped>
</style>
